import UserService from '../_services/user.service';

export default {
  namespaced: true,
  state: {
    theory: {},
  },
  actions: {
    postTheory({ commit }, payload) {
      commit('disableSubmitBtn', {}, { root: true });
      commit('postTheoryRequest');

      UserService.postTheory(payload)
        .then(
          (theory) => {
            commit('postTheorySuccess', theory);
            // commit('enableSuccess', {}, { root: true });
            commit('discover', {}, { root: true });
            commit('discoverPage', {}, { root: true });
            commit('disableTheory', {}, { root: true });
          },
          (error) => {
            commit('postTheoryFailure', error);
            commit('enableSubmitBtn', {}, { root: true });
          },
        );
    },
    postStatistic({ commit }, payload) {
      commit('postStatisticRequest');

      UserService.postEvent(payload).then();
    },
  },
  mutations: {
    postTheoryRequest(state) {
      const stateToChange = state;
      stateToChange.theory = { loading: true };
    },
    postTheorySuccess(state, theory) {
      const stateToChange = state;
      stateToChange.theory = { content: theory };
    },
    postTheoryFailure(state, error) {
      const stateToChange = state;
      stateToChange.theory = { error };
    },
    postStatisticRequest(state) {
      state.request = { loading: true };
    },
  },
};
