<template>
    <div v-if="($store.state.discover && $store.getters.isLogbook) === false" id="hud">
        <HudActionBtns></HudActionBtns>
        <HudMoveBtns v-if="!$store.getters.isLogbook && !$store.getters.isZoom && !$store.getters.isQuit && !$store.getters.isTheory"></HudMoveBtns>
        <HudReturnBtn v-if="($store.getters.isLogbook && !$store.state.discover) || $store.getters.isZoom || $store.getters.isQuit || $store.getters.isTheory"></HudReturnBtn>
    </div>
</template>

<script>
import HudMoveBtns from './HudMoveBtns.vue';
import HudActionBtns from './HudActionBtns.vue';
import HudReturnBtn from './HudReturnBtn.vue';

export default {
  name: 'Hud',
  props: {},
  components: {
    HudMoveBtns,
    HudActionBtns,
    HudReturnBtn,
  },
};
</script>
