<template>
<div id='success'>
    <div class="success-block">
        <h1 tabindex="-1" v-focus>{{ $store.state.config.success.content.title }}</h1>
        <div v-html="$store.state.config.success.content.content"></div>
        <div class="confirm-btns">
            <button
                @mouseover="$store.dispatch('enableCursor')"
                @mouseout="$store.dispatch('disableCursor')"
                v-on:click="reset">Retour à l'accueil</button>
        </div>
    </div>
</div>
</template>

<script>
export default {
  name: 'Success',
  props: {},
  methods: {
    reset() {
      this.$store.dispatch('reset');
    },
  },
};
</script>
