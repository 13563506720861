<template>
<div id="landing">
  <img class="logo" :src="$store.state.config.core.logo" :alt="$store.state.config.core.logo_alt" />
    <div class="container" :style="{ backgroundImage: 'url(' + getBackground + ')' }">
        <div class="main">
            <h1 tabindex="-1" v-focus v-html="title" class="h1"></h1>
            <div v-html="content"></div>
        </div>
        <div class="btn-section">
          <button class="btn-primary"
            @mouseover="$store.dispatch('enableCursor')"
            @mouseout="$store.dispatch('disableCursor')"
            v-on:click="nextPage">Mener l'enquête</button>
        </div>
        <a class="mentions" href="#"
            @mouseover="$store.dispatch('enableCursor')"
            @mouseout="$store.dispatch('disableCursor')"
            @click="$emit('showModal')">Mentions légales</a>
    </div>
</div>
</template>

<script>
export default {
  name: 'Landing',
  data() {
    return {
      title: this.$store.state.config.landing.content.title,
      content: this.$store.state.config.landing.content.content,
    };
  },
  computed: {
    getBackground() {
      const str = this.$store.state.config.landing.background;
      return str.replace('.jpg', `${this.$store.getters.imgSize}.jpg`);
    },
  },
  methods: {
    nextPage() {
      this.$store.dispatch('UserStore/postStatistic', {
        event: 'event.start_game_click',
      });
      this.$store.dispatch('nextPage', this.$store.state.config.landing.steps.next);
      this.$store.dispatch('disableCursor');
    },
  },
};
</script>
