import Vue from 'vue';
import Vuex from 'vuex';
import Config from './config';
import AuthenticationStore from './_store/authentication.module';
import UserStore from './_store/users.module';
import { mergeDeep } from './_helpers/merge';

Vue.use(Vuex);

function init() {
  return {
    initialized: false,
    title: '',
    discover: false,
    isDiscoverable: false,
    success: false,
    cursor: false,
    invertCursor: false,
    config: Config,
    progress: null,
    type: null,
    clues: [],
    logbook: false,
    quit: false,
    theory: false,
    highlight: false,
    tutorialDone: false,
    tutorialDelayElapsed: false,
    submitted: false,
  };
}

export default new Vuex.Store({
  modules: {
    AuthenticationStore,
    UserStore,
  },
  state: init,
  mutations: {
    overrideConfig(state, userConfig) {
      const initialState = state;
      const remoteConfig = JSON.parse(userConfig);
      initialState.config = mergeDeep(initialState.config, remoteConfig);
      initialState.title = initialState.config.core.title;
      initialState.isDiscoverable = new Date() > new Date(initialState.config.core.revelation_date);

      if (initialState.config.core.logo !== '/media/logo_white.svg') {
        initialState.config.core.logo = process.env.VUE_APP_IMAGE_URL + initialState.config.core.logo;
      }

      document.documentElement.style.setProperty('--main', initialState.config.core.color);
      document.documentElement.style.setProperty('--main-darken', initialState.config.core.color);

      initialState.initialized = true;
    },
    setTitle(state, title) {
      const initialState = state;
      initialState.title = title;
    },
    discover(state) {
      const initialState = state;
      initialState.discover = true;
      initialState.clues = initialState.config.core.clues;
    },
    enableCursor(state) {
      const initialState = state;
      initialState.cursor = true;
    },
    disableCursor(state) {
      const initialState = state;
      initialState.cursor = false;
    },
    invertCursor(state) {
      const initialState = state;
      initialState.invertCursor = !state.invertCursor;
    },
    nextPage(state, next) {
      const initialState = state;
      initialState.progress = next;
      initialState.title = initialState.config[next].title ? initialState.config[next].title : '';
    },
    discoverPage(state) {
      const initialState = state;
      initialState.progress = initialState.config.landing.steps.next;
      initialState.title = initialState.config[initialState.progress].title ? initialState.config[initialState.progress].title : '';
    },
    nextPageType(state, nextType) {
      const initialState = state;
      initialState.type = nextType;
    },
    addClue(state, clue) {
      const initialState = state;
      if (initialState.clues.indexOf(clue) === -1) {
        initialState.clues.push(clue);
      }
    },
    enableBook(state) {
      const initialState = state;
      initialState.logbook = true;
    },
    disableBook(state) {
      const initialState = state;
      initialState.logbook = false;
    },
    enableQuit(state) {
      const initialState = state;
      initialState.quit = true;
    },
    disableQuit(state) {
      const initialState = state;
      initialState.quit = false;
    },
    enableSuccess(state) {
      const initialState = state;
      initialState.success = true;
    },
    disableSuccess(state) {
      const initialState = state;
      initialState.success = false;
      initialState.submitted = false;
    },
    disableSubmitBtn(state) {
      const initialState = state;
      initialState.submitted = true;
    },
    enableSubmitBtn(state) {
      const initialState = state;
      initialState.submitted = false;
    },
    enableTheory(state) {
      const initialState = state;
      initialState.theory = true;
    },
    disableTheory(state) {
      const initialState = state;
      initialState.theory = false;
    },
    highlight(state, highlight) {
      const initialState = state;
      initialState.highlight = highlight;
    },
    doneTutorial(state) {
      const initialState = state;
      initialState.highlight = false;
      initialState.tutorialDone = true;
    },
    tutorialDelayElapsed(state) {
      const initialState = state;
      if (!initialState.discover) {
        initialState.tutorialDelayElapsed = true;
      }
    },
    reset(state) {
      const initialState = state;
      const s = init();
      Object.keys(s).forEach((key) => {
        if (key !== 'initialized' && key !== 'config' && key !== 'isDiscoverable') {
          initialState[key] = s[key];
        }
      });
    },
  },
  actions: {
    overrideConfig({ commit }, userConfig) {
      commit('overrideConfig', userConfig);
    },
    setTitle({ commit }, title) {
      commit('setTitle', title);
    },
    discover({ commit }) {
      commit('discover');
    },
    enableCursor({ commit }) {
      commit('enableCursor');
    },
    disableCursor({ commit }) {
      commit('disableCursor');
    },
    invertCursor({ commit }) {
      commit('invertCursor');
    },
    nextPage({ commit }, next) {
      let initialNext = next;
      if (initialNext === 'landing') {
        initialNext = null;
      }
      commit('nextPage', initialNext);
    },
    nextPageType({ commit }, nextType) {
      commit('nextPageType', nextType);
    },
    addClue({ commit }, clue) {
      commit('addClue', clue);
    },
    enableBook({ commit }) {
      commit('disableQuit');
      commit('disableTheory');
      commit('disableSuccess');
      commit('enableBook');
    },
    disableBook({ commit }) {
      commit('disableBook');
    },
    enableQuit({ commit }) {
      commit('disableBook');
      commit('disableTheory');
      commit('disableSuccess');
      commit('doneTutorial');
      commit('enableQuit');
    },
    enableSuccess({ commit }) {
      commit('disableBook');
      commit('disableTheory');
      commit('disableQuit');
      commit('enableSuccess');
    },
    disableQuit({ commit }) {
      commit('disableQuit');
    },
    enableTheory({ commit }) {
      commit('disableBook');
      commit('disableQuit');
      commit('disableSuccess');
      commit('enableTheory');
    },
    disableTheory({ commit }) {
      commit('disableTheory');
    },
    highlight({ commit }, highlight) {
      commit('highlight', highlight);
    },
    doneTutorial({ commit }) {
      commit('doneTutorial');
    },
    tutorialDelayElapsed({ commit }) {
      commit('tutorialDelayElapsed');
    },
    reset({ commit }) {
      commit('reset');
    },
  },
  getters: {
    title: (state) => {
      if (state.title !== state.config.core.title) {
        return `${state.title} - ${state.config.core.title}`;
      }

      return state.title;
    },
    config: (state) => state.config,
    countClues: (state) => state.clues.length,
    isQuit: (state) => state.quit,
    isSuccess: (state) => state.success,
    isTheory: (state) => state.theory,
    isLogbook: (state) => state.logbook,
    isTutorial: (state) => state.type === 'tutorial' && !state.logbook && !state.quit && !state.theory,
    displayTutorial: (state) => !state.tutorialDone && state.tutorialDelayElapsed && !state.quit,
    isFullBoard: (state) => state.type !== 'zoom' && !state.logbook && !state.quit && !state.theory,
    isZoom: (state) => state.type === 'zoom' && !state.logbook && !state.quit && !state.theory,
    imgSize: () => '_1366x768px',
    inClues: (state) => (clue) => state.clues.indexOf(clue) !== -1,
  },
});
