import UserService from '../_services/user.service';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: {}, user: null };

export default {
  namespaced: true,
  state: initialState,
  actions: {
    login({ dispatch, commit }) {
      const username = btoa(window.location.host);
      commit('loginRequest', { username });

      UserService.login(username)
        .then(
          (dataFromApi) => {
            commit('loginSuccess', dataFromApi);
            dispatch('overrideConfig', dataFromApi.core, { root: true });
          },
          (error) => {
            commit('loginFailure', error);
          },
        );
    },
    logout({ commit }) {
      UserService.logout();
      commit('logout');
    },
  },
  mutations: {
    loginRequest(state, userMutated) {
      const stateToChange = state;
      stateToChange.status = { loggingIn: true };
      stateToChange.user = userMutated;
    },
    loginSuccess(state, userMutated) {
      const stateToChange = state;
      stateToChange.status = { loggedIn: true };
      stateToChange.user = userMutated;
    },
    loginFailure(state) {
      const stateToChange = state;
      stateToChange.status = {};
      stateToChange.user = null;
    },
    logout(state) {
      const stateToChange = state;
      stateToChange.status = {};
      stateToChange.user = null;
    },
  },
};
