<template>
<div class="move-btns" aria-labelledby="move-label" v-if="!$store.state.success">
    <span id="move-label" v-bind:class="{ 'sr-only': $store.state.highlight !== 'move' }">
      Se déplacer
      <svg width="30px" height="20px" viewBox="0 0 30 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <g id="fleche_courte" fill="#E94243">
          <path d="M23.5428787,10.9146957 C23.5428787,10.9146957 20.114852,13.532857 18.2411495,15.2981634 C16.3672037,17.0637101 15.7642038,17.9490067 15.82842,18.841032 C15.8931227,19.7323363 16.5917172,20.1836357 16.9047712,19.9298699 C17.2170955,19.6758638 20.8956628,16.1551036 23.2882031,14.3477432 C25.6812299,12.5411037 28.2340675,11.0910821 28.7227431,10.8454868 C29.2119053,10.6008527 29.9739863,10.0731352 29.9987971,9.30703164 C30.0236079,8.54092807 29.6645809,7.55374066 28.9253647,7.07408485 C28.1868783,6.59370812 25.3440948,5.41787852 23.5222031,4.26559915 C21.7003113,3.11307948 19.8740412,1.23338621 19.4785278,0.70230438 C19.082771,0.171703162 18.1080954,-0.23946472 17.7478522,0.160168353 C17.3873658,0.558840192 17.5622577,1.95359086 18.6680413,3.00421972 C19.7730953,4.05460827 22.8523112,7.03179055 26.0976623,8.32152639 C26.0976623,8.32152639 19.2321224,8.84636021 15.0344741,9.15203265 C10.8370691,9.45698416 6.51123163,9.60885915 4.64677234,9.57137102 C2.78231305,9.53364258 1.71155639,9.41372863 1.36761047,9.22196243 C1.02342131,9.03019623 0.190799768,9.32577571 0.0270970797,9.8695939 C-0.136119122,10.4129315 0.450826771,11.7471244 1.44179965,11.8096046 C2.43252929,11.8713639 8.19301526,11.925193 12.7046905,11.6082261 C17.2161226,11.2914994 23.5428787,10.9146957 23.5428787,10.9146957"></path>
        </g>
      </svg>
    </span>
    <button
        @mouseover="$store.dispatch('enableCursor')"
        @mouseout="$store.dispatch('disableCursor')"
        aria-labelledby="move-left-btn-label"
        class="move-left-btn focus"
        v-bind:class="{ disabled: !leftActive, highlight: $store.state.highlight === 'move' }"
        v-on:click="leftAction">
        <svg width="26px" height="26px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26"><defs/><path fill="none" fill-rule="evenodd" d="M6.53 25.47A1.83 1.83 0 016 24.2V7.8c0-.5.19-.93.53-1.27C6.87 6.19 7.3 6 7.8 6h16.4a1.8 1.8 0 010 3.6H9.6v14.6a1.8 1.8 0 01-3.07 1.27z"/></svg>
        <span class="btn-hover-text" id="move-left-btn-label" role="tooltip">{{ leftText }}</span>
    </button>
    <button
        @mouseover="$store.dispatch('enableCursor')"
        @mouseout="$store.dispatch('disableCursor')"
        aria-labelledby="move-center-btn-label"
        class="move-center-btn focus"
        v-bind:class="{ disabled: !returnActive, highlight: $store.state.highlight === 'move' }"
        v-on:click="returnAction">
        <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="14px" viewBox="0 0 12 12"><defs/><path fill="#FFF" fill-rule="evenodd" d="M11.85 5.85a.6.6 0 00-.05-.84L6.44.16a.65.65 0 00-.87.01L.19 5.27a.6.6 0 00-.03.84l.14.15c.22.24.58.27.8.06l.4-.37v5.45c0 .33.27.6.6.6h2.09a.6.6 0 00.58-.6V7.59h2.68v3.8c0 .34.23.61.55.61h2.22a.6.6 0 00.59-.6V6.03l.25.22c.13.12.42.03.64-.22l.15-.18z"/></svg>
        <span class="btn-hover-text" id="move-center-btn-label" role="tooltip">Entrée du bureau</span>
    </button>
    <button
        @mouseover="$store.dispatch('enableCursor')"
        @mouseout="$store.dispatch('disableCursor')"
        aria-labelledby="move-right-btn-label"
        class="move-right-btn focus"
        v-bind:class="{ disabled: !rightActive, highlight: $store.state.highlight === 'move' }"
        v-on:click="rightAction">
        <svg width="26px" height="26px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26"><defs/><path fill="none" fill-rule="evenodd" d="M19.47 25.47c.31-.3.53-.77.53-1.27V7.8c0-.5-.19-.93-.53-1.27A1.76 1.76 0 0018.2 6H1.8a1.8 1.8 0 000 3.6h14.6v14.6a1.8 1.8 0 003.07 1.27z"/></svg>
        <span class="btn-hover-text" id="move-right-btn-label" role="tooltip">{{ rightText }}</span>
    </button>
</div>
</template>

<script>
export default {
  name: 'HudMoveBtns',
  props: {},
  computed: {
    returnActive() {
      let action = '';
      if (!this.$store.getters.displayTutorial) {
        action = this.$store.state.config[this.$store.state.progress].steps.previous;
      }
      return action;
    },
    leftActive() {
      let action = '';
      if (!this.$store.getters.displayTutorial) {
        action = this.$store.state.config[this.$store.state.progress].steps.next.left;
      }
      return action;
    },
    rightActive() {
      let action = '';
      if (!this.$store.getters.displayTutorial) {
        action = this.$store.state.config[this.$store.state.progress].steps.next.right;
      }
      return action;
    },
    leftText() {
      return this.$store.state.config[this.$store.state.progress].steps.next.left_text ? this.$store.state.config[this.$store.state.progress].steps.next.left_text : 'Aller à gauche';
    },
    rightText() {
      return this.$store.state.config[this.$store.state.progress].steps.next.right_text ? this.$store.state.config[this.$store.state.progress].steps.next.right_text : 'Aller à droite';
    },
  },
  methods: {
    returnAction() {
      if (this.returnActive
          && !this.$store.getters.displayTutorial) {
        this.$store.dispatch('nextPage', this.returnActive);
        this.setType();
      }
    },
    leftAction() {
      if (this.leftActive
          && !this.$store.getters.displayTutorial) {
        this.$store.dispatch('nextPage', this.leftActive);
        this.setType();
      }
    },
    rightAction() {
      if (this.rightActive
          && !this.$store.getters.displayTutorial) {
        this.$store.dispatch('nextPage', this.rightActive);
        this.setType();
      }
    },
    setType() {
      this.$store.dispatch('nextPageType', this.$store.state.config[this.$store.state.progress].type);
    },
  },
};
</script>
