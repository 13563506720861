<template>
<div>
    <progressive-background id="book" :src="getBook" :blur="5">
        <div slot="content" slot-scope="{ visible }">
            <transition name="fade" mode="out-in">
            <div v-show="!visible">
                <div class="clue" v-for="(item, index) in $store.state.clues" :key="index">
                    <progressive-img class="book-clue" :src="getClueImg(item)" v-show="$store.getters.inClues(item)" :blur="0" />
                    <div class="book-block" :style="getClueBlockCss(item)">
                        <h2 class="book-title">{{ getClueTitle(item) }}</h2>
                        <p class="book-p">{{ getClueParaph(item) }}</p>
                        <button
                            v-if="$store.state.discover"
                            @mouseover="$store.dispatch('enableCursor')"
                            @mouseout="$store.dispatch('disableCursor')"
                            @click="setZoom(index)"
                            aria-labelledby="zoom-btn-label"
                            class="zoom-btn focus">
                            <svg width="15px" height="20px" viewBox="0 0 24 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                              <path d="M23.9481334,11.9891 C23.9481334,5.49425 18.5927534,0.2102 12.0100591,0.2102 C5.42736486,0.2102 0.0720608108,5.49425 0.0720608108,11.9891 C0.0720608108,15.97205 2.13309122,19.69475 5.49349662,21.859625 L5.49349662,25.570475 C5.49349662,29.115725 8.41674831,31.999925 12.0099071,31.999925 C15.6030659,31.999925 18.5263176,29.11565 18.5263176,25.570475 L18.5263176,23.486225 C18.5263176,23.4857 18.5262416,21.859775 18.5262416,21.859775 C21.887027,19.694825 23.9481334,15.9722 23.9481334,11.9891 Z M16.2250845,25.57055 C16.2250845,27.86375 14.3342483,29.7293 12.0100591,29.7293 C9.68586993,29.7293 7.79503378,27.863675 7.79503378,25.57055 L7.79503378,24.6215 L16.2250845,24.6215 L16.2250845,25.57055 Z M16.8025591,20.240075 C16.4452956,20.442575 16.2250845,20.818025 16.2250845,21.2246 L16.2250845,22.350875 L13.1606757,22.350875 L13.1606757,16.47995 C14.6547973,16.000325 15.7381419,14.6138 15.7381419,12.98165 C15.7381419,12.35465 15.2229223,11.8463 14.5874493,11.8463 C13.9519764,11.8463 13.4367568,12.35465 13.4367568,12.98165 C13.4367568,13.757825 12.796723,14.3894 12.0100591,14.3894 C11.2233953,14.3894 10.5832855,13.7579 10.5832855,12.98165 C10.5832855,12.35465 10.0680659,11.8463 9.43259291,11.8463 C8.79711993,11.8463 8.28190034,12.35465 8.28190034,12.98165 C8.28190034,14.613725 9.36516892,16.000325 10.8593666,16.47995 L10.8593666,22.350875 L7.79518581,22.350875 L7.79518581,21.224525 C7.79518581,20.818025 7.57497466,20.442575 7.21771115,20.240075 C4.22969595,18.54635 2.37359797,15.3848 2.37359797,11.9891 C2.37359797,6.7463 6.6965625,2.4809 12.0102872,2.4809 C17.3240118,2.4809 21.6469764,6.746225 21.6469764,11.9891 C21.6468243,15.384875 19.7905743,18.5465 16.8025591,20.240075 Z" id="Shape" fill-rule="nonzero"></path>
                            </svg>
                            <span class="btn-text" id="zoom-btn-label" role="tooltip">Réponse</span>
                        </button>
                    </div>
                </div>
            </div>
            </transition>
        </div>
    </progressive-background>
</div>
</template>

<script>
export default {
  name: 'BoardBook',
  computed: {
    getBook() {
      const str = '/media/book/book.png';
      return str.replace('.png', `${this.$store.getters.imgSize}.png`);
    },
  },
  methods: {
    getClueImg(id) {
      return this.$store.state.config[id].content['book-img'];
    },
    getClueBlockCss(id) {
      return this.$store.state.config[id].content['book-block-css'];
    },
    getClueTitle(id) {
      return this.$store.state.config[id].content['book-title'];
    },
    getClueParaph(id) {
      return this.$store.state.config[id].content['book-paraph'];
    },
    setZoom(position) {
      this.backgroundHover = '';
      this.$store.dispatch('disableBook');
      this.$store.dispatch('nextPage', this.$store.state.clues[position]);
      this.$store.dispatch('nextPageType', 'zoom');

      const title = document.querySelector('h1');
      title.focus();

      this.$emit('clicked-show-detail');
    },
  },
};
</script>
