<template>
<div id="landing-bis">
    <img class="logo" :src="$store.state.config.core.logo" :alt="$store.state.config.core.logo_alt" />
    <div class="container" :style="{ backgroundImage: 'url(' + getBackground + ')' }">
        <div class="blur" :style="{ backgroundImage: 'url(' + getBackgroundPlacehoder + ')' }"></div>
        <div class="main">
            <h1 v-html="title" tabindex="-1" v-focus class="h1"></h1>
            <div v-html="content"></div>
        </div>
        <div class="btn-section" v-if="!$store.state.discover">
          <button class="btn-primary arrow"
              @mouseover="$store.dispatch('enableCursor')"
              @mouseout="$store.dispatch('disableCursor')"
              v-on:click="nextPage">Commencer</button>
        </div>
        <div class="btn-section" v-else>
          <button class="btn-primary"
              @mouseover="$store.dispatch('enableCursor')"
              @mouseout="$store.dispatch('disableCursor')"
              v-on:click="book">Découvrir les situations</button>
        </div>
        <a class="mentions" href="#"
            @mouseover="$store.dispatch('enableCursor')"
            @mouseout="$store.dispatch('disableCursor')"
            @click="$emit('showModal')">Mentions légales</a>
    </div>
</div>
</template>

<script>
export default {
  name: 'LandingBis',
  data() {
    return {
      background: this.$store.state.config.landingBis.background,
    };
  },
  computed: {
    title() {
      return this.$store.state.discover ? this.$store.state.config.landingBis.content.title_discovered : this.$store.state.config.landingBis.content.title;
    },
    content() {
      return this.$store.state.discover ? this.$store.state.config.landingBis.content.content_discovered : this.$store.state.config.landingBis.content.content;
    },
    getBackground() {
      const str = this.$store.state.config.landingBis.background;
      return str.replace('.jpg', `${this.$store.getters.imgSize}.jpg`);
    },
    getBackgroundPlacehoder() {
      const str = this.$store.state.config.landingBis.background;
      return str.replace('.jpg', '_thumbnail.jpg');
    },
  },
  methods: {
    nextPage() {
      this.$store.dispatch('disableCursor');
      this.$store.dispatch('nextPage', this.$store.state.config.landingBis.steps.next);
      this.$store.dispatch('nextPageType', this.$store.state.config.landingBis.steps.next);
    },
    book() {
      this.$store.dispatch('disableCursor');
      this.$store.dispatch('UserStore/postStatistic', {
        event: 'event.discover_click',
      });
      this.$store.dispatch('nextPage', this.$store.state.config.landingBis.steps.next);
      this.$store.dispatch('nextPageType', this.$store.state.config.landingBis.steps.next);
      this.$store.dispatch('enableBook');
      this.$store.dispatch('setTitle', 'Carnet d\'indices');
    },
  },
};
</script>
