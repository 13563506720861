<template>
<div id='settings'>
    <HudQuitBtn v-if="$store.state.progress !== null && $store.state.progress !== 'landingBis'"></HudQuitBtn>
    <button
        @mouseover="$store.dispatch('enableCursor')"
        @mouseout="$store.dispatch('disableCursor')"
        aria-labelledby="fullscreen-btn-label"
        @click="$emit('toggle')"
        class="fullscreen-btn focus">
        <svg v-show="!fullscreen" width="130px" height="130px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130 130"><defs/><path d="M130 4.06v.06l-.07 36.6c0 2.3-1.75 4.06-4.06 4.06a3.98 3.98 0 01-4.06-4.06l.06-32.6H89.44a3.9 3.9 0 01-4.03-4c0-2.3 1.72-4.12 4.03-4.12h36.5C128.2 0 130 1.8 130 4.06zM40.69 0c2.3 0 4.06 1.75 4.06 4.06 0 2.31-1.75 4.06-4.06 4.06H8.19l-.06 32.54a3.9 3.9 0 01-4 4.03A4.01 4.01 0 010 40.66l.06-36.6C.06 1.8 1.86 0 4.13 0h36.56zm-.13 121.88a3.9 3.9 0 014.03 4c0 2.3-1.72 4.12-4.03 4.12H4.06A4.03 4.03 0 010 125.94v-.03-36.53c0-2.31 1.76-4.07 4.06-4.07 2.31 0 4.07 1.76 4.07 4.07v32.5h32.43zm85.31-36.47c2.31 0 4.13 1.72 4.13 4.03v36.5c0 2.26-1.8 4.06-4.06 4.06h-.04-36.52a3.98 3.98 0 01-4.07-4.06c0-2.31 1.76-4.06 4.07-4.06h32.5V89.44a3.9 3.9 0 014-4.03z"/></svg>
        <svg v-show="fullscreen" width="130px" height="114px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130 114"><defs/><path d="M89.23 44.68a4.02 4.02 0 01-4.05-4.05v-.03V4.1c0-2.3 1.75-4.05 4.05-4.05s4.05 1.75 4.05 4.05v32.48h32.41c2.3 0 4.02 1.69 4.02 3.99a4 4 0 01-4.02 4.11H89.23zM40.51.08a4 4 0 014.12 4.02v36.47c0 2.26-1.8 4.05-4.05 4.05h-.04H4.05A3.97 3.97 0 010 40.57c0-2.3 1.75-4.05 4.05-4.05h32.48V4.1A3.9 3.9 0 0140.5.08zm.03 68.85c2.27 0 4.06 1.79 4.06 4.05v36.53c0 2.3-1.75 4.05-4.06 4.05a3.97 3.97 0 01-4.05-4.05V77.03H4.08a3.89 3.89 0 01-4.02-3.98 4 4 0 014.02-4.12h36.46zm85.18 0c2.3 0 4.05 1.75 4.05 4.05s-1.75 4.06-4.05 4.06H93.25v32.41a3.9 3.9 0 01-3.99 4.01 4 4 0 01-4.11-4.01V72.98c0-2.26 1.79-4.05 4.05-4.05h36.52z"/></svg>
        <span class="btn-text sr-only" id="fullscreen-btn-label" role="tooltip">Plein écran</span>
    </button>
</div>
</template>

<script>
import HudQuitBtn from './HudQuitBtn.vue';

export default {
  name: 'Settings',
  props: {
    fullscreen: Boolean,
  },
  components: {
    HudQuitBtn,
  },
};
</script>
