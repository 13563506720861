<template>
    <a href="#"
      @mouseover="enableCursorPoint"
      @mouseout="disableCursorPoint"
      @click="zoom" class="point focus" v-bind:style="styleInline">
      <span class="text-hover"><span>{{ text }}</span></span>
    </a>
</template>

<script>
export default {
  name: 'Point',
  props: {
    styleInline: String,
    text: String,
  },
  methods: {
    enableCursorPoint() {
      this.$emit('enableCursor');
      this.$store.dispatch('enableCursor');
    },
    disableCursorPoint() {
      this.$emit('disableCursor');
      this.$store.dispatch('disableCursor');
    },
    zoom() {
      this.$emit('setZoom');
      this.$store.dispatch('disableCursor');
    },
  },
};
</script>
