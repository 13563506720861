<template>
<div id='quit'>
    <div class="quit-block">
        <h1 tabindex="-1" v-focus>Vous quittez le jeu</h1>
        <p>Souhaitez-vous répondre a la question ultime avant de quitter la partie ?</p>
        <div class="confirm-btns">
            <button
                @mouseover="$store.dispatch('enableCursor')"
                @mouseout="$store.dispatch('disableCursor')"
                @click="theory">Oui</button>
            <button
                @mouseover="$store.dispatch('enableCursor')"
                @mouseout="$store.dispatch('disableCursor')"
                @click="reloadPage">Non</button>
        </div>
    </div>
</div>
</template>

<script>
export default {
  name: 'Quit',
  props: {},
  methods: {
    reloadPage() {
      this.$store.dispatch('reset');
    },
    theory() {
      this.$store.dispatch('setTitle', 'Proposer une théorie');
      this.$store.dispatch('disableCursor');
      this.$store.dispatch('enableTheory');
    },
  },
};
</script>
