<template>
    <div id="cursor" v-bind:class="{ active: $store.state.cursor, invert: $store.state.invertCursor }">
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
    </div>
</template>

<script>
export default {
  name: 'CursorCustom',
  props: {},
  mounted() {
    const app = document.getElementById('app');
    const cursor = document.getElementById('cursor');
    app.onmousemove = (e) => {
      cursor.style.transform = `translate(${e.clientX}px, ${e.clientY}px)`;
    };
  },
};
</script>
