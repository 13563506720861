<template>
<div class="action-btns" v-if="!$store.state.discover && !$store.state.success">
    <button
        @mouseover="$store.dispatch('enableCursor')"
        @mouseout="$store.dispatch('disableCursor')"
        @click="book"
        aria-labelledby="logbook-label"
        v-bind:class="{ active: $store.getters.isLogbook || $store.state.highlight === 'logbook', disabled: !bookActive, highlight: highlightClues }"
        class="logbook focus">
        <span id="logbook-label" role="tooltip" v-bind:class="{ 'sr-only': $store.state.highlight !== 'logbook' }">
          Carnet d'indices
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20px"
            height="30.01px" viewBox="0 0 20 30.01" style="enable-background:new 0 0 20 30.01;" xml:space="preserve">
            <path d="M9.09,23.55c0,0-2.62-3.43-4.39-5.3s-2.65-2.48-3.54-2.41s-1.34,0.76-1.09,1.07s3.77,4,5.58,6.39
              c1.3,1.72,2.47,3.54,3.5,5.43c0.25,0.49,0.78,1.25,1.54,1.28c0.88,0.03,1.71-0.37,2.24-1.07c0.48-0.74,1.65-3.59,2.8-5.41
              c1-1.49,2.2-2.84,3.57-4c0.53-0.4,0.94-1.37,0.54-1.73s-1.79-0.19-2.84,0.92s-4,4.18-5.32,7.43c0,0-0.53-6.87-0.83-11.07
              S10.39,6.56,10.43,4.7c-0.06-1.11,0.06-2.21,0.35-3.28c0.19-0.57-0.09-1.19-0.65-1.41C9.21-0.09,8.36,0.54,8.19,1.45
              c-0.06,1-0.12,6.75,0.2,11.26S9.09,23.55,9.09,23.55"/>
          </svg>
        </span>
        <span class="clues_container">
        <svg width="27px" height="32px" viewBox="0 0 27 32" preserveAspectRatio version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <path d="M0,2.8125 L0,29.1875 C0,30.7382812 1.27749023,32 2.84765625,32 L26.578125,32 C26.578125,30.5935059 26.578125,1.76342775 26.578125,0 L2.84765625,0 C1.27749023,0 0,1.26171875 0,2.8125 Z M1.8984375,29.1875 L1.8984375,5.46386719 C2.19556271,5.56787112 2.51493533,5.625 2.84765625,5.625 L3.796875,5.625 L3.796875,30.125 L2.84765625,30.125 C2.32434999,30.125 1.8984375,29.7043457 1.8984375,29.1875 Z M5.6953125,30.125 L5.6953125,5.625 L9.4921875,5.625 L9.4921875,16.3259277 L13.2890625,12.5759278 L17.0859375,16.3259277 L17.0859375,5.625 L20.8828125,5.625 L20.8828125,22.5 L24.6796875,22.5 L24.6796875,30.125 L5.6953125,30.125 Z M11.390625,5.625 L15.1875,5.625 L15.1875,11.7990723 L13.2890625,9.92407225 L11.390625,11.7990723 L11.390625,5.625 Z M22.78125,16.875 L24.6796875,16.875 L24.6796875,20.625 L22.78125,20.625 L22.78125,16.875 Z M22.78125,15 L22.78125,11.25 L24.6796875,11.25 L24.6796875,15 L22.78125,15 Z M24.6796875,9.375 L22.78125,9.375 L22.78125,5.625 L24.6796875,5.625 L24.6796875,9.375 Z M24.6796875,3.75 L2.84765625,3.75 C2.32434999,3.75 1.8984375,3.32934569 1.8984375,2.8125 C1.8984375,2.29565431 2.32434999,1.875 2.84765625,1.875 L24.6796875,1.875 L24.6796875,3.75 Z" fill-rule="nonzero"></path>
        </svg>
        <span class="progress">{{ $store.getters.countClues }}/8</span>
        </span>
    </button>
    <button
        @mouseover="$store.dispatch('enableCursor')"
        @mouseout="$store.dispatch('disableCursor')"
        @click="theory"
        aria-labelledby="theory-label"
        v-bind:class="{ active: $store.getters.isTheory || $store.state.highlight === 'theory', disabled: !theoryActive }"
        class="theory focus">
        <span id="theory-label" role="tooltip" v-bind:class="{ 'sr-only': $store.state.highlight !== 'theory' }">
          Proposer une théorie
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="20px"
            height="30.01px" viewBox="0 0 20 30.01" style="enable-background:new 0 0 20 30.01;" xml:space="preserve">
            <path d="M9.09,23.55c0,0-2.62-3.43-4.39-5.3s-2.65-2.48-3.54-2.41s-1.34,0.76-1.09,1.07s3.77,4,5.58,6.39
              c1.3,1.72,2.47,3.54,3.5,5.43c0.25,0.49,0.78,1.25,1.54,1.28c0.88,0.03,1.71-0.37,2.24-1.07c0.48-0.74,1.65-3.59,2.8-5.41
              c1-1.49,2.2-2.84,3.57-4c0.53-0.4,0.94-1.37,0.54-1.73s-1.79-0.19-2.84,0.92s-4,4.18-5.32,7.43c0,0-0.53-6.87-0.83-11.07
              S10.39,6.56,10.43,4.7c-0.06-1.11,0.06-2.21,0.35-3.28c0.19-0.57-0.09-1.19-0.65-1.41C9.21-0.09,8.36,0.54,8.19,1.45
              c-0.06,1-0.12,6.75,0.2,11.26S9.09,23.55,9.09,23.55"/>
          </svg>
        </span>
        <svg width="24px" height="32px" viewBox="0 0 24 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <path d="M23.9481334,11.9891 C23.9481334,5.49425 18.5927534,0.2102 12.0100591,0.2102 C5.42736486,0.2102 0.0720608108,5.49425 0.0720608108,11.9891 C0.0720608108,15.97205 2.13309122,19.69475 5.49349662,21.859625 L5.49349662,25.570475 C5.49349662,29.115725 8.41674831,31.999925 12.0099071,31.999925 C15.6030659,31.999925 18.5263176,29.11565 18.5263176,25.570475 L18.5263176,23.486225 C18.5263176,23.4857 18.5262416,21.859775 18.5262416,21.859775 C21.887027,19.694825 23.9481334,15.9722 23.9481334,11.9891 Z M16.2250845,25.57055 C16.2250845,27.86375 14.3342483,29.7293 12.0100591,29.7293 C9.68586993,29.7293 7.79503378,27.863675 7.79503378,25.57055 L7.79503378,24.6215 L16.2250845,24.6215 L16.2250845,25.57055 Z M16.8025591,20.240075 C16.4452956,20.442575 16.2250845,20.818025 16.2250845,21.2246 L16.2250845,22.350875 L13.1606757,22.350875 L13.1606757,16.47995 C14.6547973,16.000325 15.7381419,14.6138 15.7381419,12.98165 C15.7381419,12.35465 15.2229223,11.8463 14.5874493,11.8463 C13.9519764,11.8463 13.4367568,12.35465 13.4367568,12.98165 C13.4367568,13.757825 12.796723,14.3894 12.0100591,14.3894 C11.2233953,14.3894 10.5832855,13.7579 10.5832855,12.98165 C10.5832855,12.35465 10.0680659,11.8463 9.43259291,11.8463 C8.79711993,11.8463 8.28190034,12.35465 8.28190034,12.98165 C8.28190034,14.613725 9.36516892,16.000325 10.8593666,16.47995 L10.8593666,22.350875 L7.79518581,22.350875 L7.79518581,21.224525 C7.79518581,20.818025 7.57497466,20.442575 7.21771115,20.240075 C4.22969595,18.54635 2.37359797,15.3848 2.37359797,11.9891 C2.37359797,6.7463 6.6965625,2.4809 12.0102872,2.4809 C17.3240118,2.4809 21.6469764,6.746225 21.6469764,11.9891 C21.6468243,15.384875 19.7905743,18.5465 16.8025591,20.240075 Z" id="Shape" fill-rule="nonzero"></path>
        </svg>
    </button>
</div>
</template>

<script>
export default {
  name: 'HudActionBtns',
  props: {},
  computed: {
    theoryActive() {
      let active = false;
      if (!this.$store.getters.displayTutorial && this.$store.getters.countClues >= 6) {
        active = true;
      }
      return active;
    },
    bookActive() {
      let active = false;
      if (!this.$store.getters.displayTutorial) {
        active = true;
      }
      return active;
    },
    highlightClues() {
      return this.$store.getters.countClues === 8 && !this.$store.getters.isLogbook;
    },
  },
  methods: {
    theory() {
      if (!this.$store.getters.displayTutorial) {
        this.$store.dispatch('UserStore/postStatistic', {
          event: 'event.theory_btn_click',
        });
        this.$store.dispatch('enableTheory');
        this.$store.dispatch('setTitle', 'Proposer une théorie');
      }
    },
    book() {
      if (!this.$store.getters.displayTutorial) {
        this.$store.dispatch('UserStore/postStatistic', {
          event: 'event.book_btn_click',
        });
        this.$store.dispatch('enableBook');
        this.$store.dispatch('setTitle', 'Carnet d\'indices');
      }
    },
  },
};
</script>
