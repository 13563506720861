<template>
    <button
        @mouseover="$store.dispatch('enableCursor')"
        @mouseout="$store.dispatch('disableCursor')"
        @click="quit"
        aria-labelledby="quit-btn-label"
        class="quit-btn focus"
        v-bind:class="{ active: $store.getters.isQuit }">
        <span class="btn-overlay"></span>
        <span class="btn-text" id="quit-btn-label" role="tooltip">Quitter le jeu</span>&nbsp;
        <svg width="28px" height="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 30"><defs/><path d="M19.56 14.1l-7.6-7.52a1.3 1.3 0 00-1.82 0c-.5.5-.5 1.3 0 1.8l5.4 5.34H1.55c-.7 0-1.28.58-1.28 1.28 0 .7.58 1.27 1.28 1.27h13.97l-5.39 5.34a1.27 1.27 0 00.91 2.18c.33 0 .66-.12.91-.37l7.59-7.51c.5-.51.5-1.32 0-1.82z"/><path d="M25.85 0h-8.73a1.3 1.3 0 00-1.33 1.28c0 .71.6 1.29 1.33 1.29h7.4v24.86h-7.4a1.3 1.3 0 00-1.33 1.29c0 .7.6 1.28 1.33 1.28h8.73a1.3 1.3 0 001.32-1.28V1.28c0-.7-.6-1.28-1.32-1.28z"/></svg>
    </button>
</template>

<script>
export default {
  name: 'HudQuitBtn',
  props: {},
  methods: {
    quit() {
      this.$store.dispatch('UserStore/postStatistic', {
        event: 'event.quit_btn_click',
      });
      if (this.$store.state.discover) {
        this.$store.dispatch('reset');
      } else {
        this.$store.dispatch('enableQuit');
        this.$store.dispatch('setTitle', 'Quitter le jeu');
      }
    },
  },
};
</script>
