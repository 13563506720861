import { authHeader } from '../_helpers/auth-header';

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

function login(username) {
  const apiUrl = process.env.VUE_APP_API_URL;
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };

  return fetch(`${apiUrl}/games/${username}`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      // login successful if there's a jwt token in the response
      if (user.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user));
      }

      return user;
    });
}

function postTheory(payload) {
  const apiUrl = process.env.VUE_APP_API_URL;
  const requestOptions = {
    method: 'POST',
    headers: Object.assign(authHeader(), { 'Content-Type': 'application/json' }),
    body: JSON.stringify(payload),
  };

  return fetch(`${apiUrl}/players`, requestOptions).then(handleResponse);
}

function postEvent(payload) {
  const apiUrl = process.env.VUE_APP_API_URL;
  const requestOptions = {
    method: 'POST',
    headers: Object.assign(authHeader(), { 'Content-Type': 'application/json' }),
    body: JSON.stringify(payload),
  };

  return fetch(`${apiUrl}/statistics`, requestOptions).then(handleResponse);
}

export default {
  login,
  logout,
  postTheory,
  postEvent,
};
