import 'isomorphic-fetch';
import Vue from 'vue';
import VueProgressiveImage from 'vue-progressive-image';
import fullscreen from 'vue-fullscreen';
import VueEsc from 'vue-esc';
import vueHeadful from 'vue-headful';
import App from './App.vue';
import store from './store';

Vue.config.productionTip = false;
Vue.use(VueProgressiveImage, {
  cache: true,
  blur: 30,
});
Vue.use(fullscreen);
Vue.use(VueEsc);
Vue.directive('focus', {
  inserted: (el) => {
    el.focus();
  },
});

Vue.component('vue-headful', vueHeadful);

new Vue({
  store,
  render: (h) => h(App),
}).$mount('#app');

// const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
// let type = connection.effectiveType;
//
// function updateConnectionStatus() {
//   console.log(`Connection type changed from ${type} to ${connection.effectiveType}`);
//   type = connection.effectiveType;
// }
//
// connection.addEventListener('change', updateConnectionStatus);
