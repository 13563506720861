<template>
  <div id="app" v-if="$store.state.initialized">
    <vue-headful :title="title"/>
    <fullscreen v-show="landscape" ref="fullscreen" @change="fullscreenChange">
      <CursorCustom />
      <Settings @toggle="toggle" :fullscreen="fullscreen" />
      <LandingBis @showModal="showModal()" v-if="$store.state.progress === 'landingBis'" />
      <div v-else-if="$store.state.progress !== null">
        <Board />
        <Hud />
      </div>
      <Landing v-else @showModal="showModal()" />
      <Modal v-if="isModalVisible" @close="closeModal">
        <h1 tabindex="0" slot="title">Mentions légales</h1>
        <div slot="body">
          <h2>Editeur :</h2>
          <p><strong>Agence BBIRD</strong></p>
          <p>
            Siège social : 1 Rue de Quebec, 76000 Rouen <br>
            Tél: 02 79 02 76 00<br>
            E-mail : bonjour@agence-bbird.fr<br>
            Siret : 82904808100022<br>
            RCS Rouen 829 048 081<br>
            Directrice de la publication : Emmanuelle BUREL
          </p>
          <h2>Conception / Développement :</h2>
          <p><strong>Agence Arcange</strong></p>
          <p>Siège social : 1 Rue de Quebec, 76000 Rouen</p>
          <h2>Crédits photos / images :</h2>
          <p>Agence BBird, Flaticon</p>
          <h2>Hébergement : </h2>
          <p>
            Le présent site internet est hébergé par la société OVH SAS. <br>
            Siège social : 2 rue Kellermann / 59100 Roubaix / France<br>
            Tél. 0 820 698 765
          </p>
          <p>RCS Lille Métropole : 424 761 419 00045</p>

          <h2>Traitement des données :</h2>
          <p>Les informations recueillies sur le formulaire sont enregistrées dans un fichier informatisé par Agence Arcange pour l’envoi de contenu pédagogique aux participants sur la thématique handicap. Elles sont conservées pendant 30 jours afin d’établir des statistiques sur les participations à l’opération de sensibilisation. Vous disposez d’un droit d’accès, de rectification et de suppression des données qui vous concernent. Vous pouvez demander la portabilité de ces dernières. Vous avez également le droit de vous opposer aux traitements réalisés ou d’en demander la limitation. Vous pouvez émettre des directives quant au sort de vos données personnelles après votre décès. Vous pouvez exercer votre droit d'accès aux données vous concernant et les faire rectifier en contactant contact@agencearcange.fr</p>
        </div>
      </Modal>
      <Quit v-if="$store.state.quit" />
      <Success v-if="$store.state.success" />
      <Theory v-show="$store.state.theory" />
    </fullscreen>
    <NotAvailable v-if="!landscape" />
  </div>
</template>

<script>
import Hud from './components/Hud.vue';
import Settings from './components/Settings.vue';
import CursorCustom from './components/Cursor.vue';
import Board from './components/Board.vue';
import Landing from './components/Landing.vue';
import LandingBis from './components/LandingBis.vue';
import Modal from './components/Modal.vue';
import Quit from './components/Quit.vue';
import Success from './components/Success.vue';
import Theory from './components/Theory.vue';
import NotAvailable from './components/NotAvailable.vue';

export default {
  name: 'app',
  components: {
    CursorCustom,
    Settings,
    Hud,
    Board,
    Landing,
    LandingBis,
    Modal,
    Quit,
    Success,
    Theory,
    NotAvailable,
  },
  data() {
    return {
      windowWidth: 0,
      windowHeight: 0,
      landscape: false,
      txt: '',
      isModalVisible: false,
      fullscreen: false,
    };
  },
  watch: {
    windowWidth(newWidth) {
      if (this.windowHeight > newWidth) {
        this.landscape = false;
      } else {
        this.landscape = true;
      }
    },
    windowHeight(newHeight) {
      if (newHeight > this.windowWidth) {
        this.landscape = false;
      } else {
        this.landscape = true;
      }
    },
  },
  computed: {
    title() {
      return this.$store.getters.title;
    },
  },
  methods: {
    showModal() {
      this.$store.dispatch('setTitle', 'Mentions legales');
      this.isModalVisible = true;
    },
    closeModal() {
      if (this.$store.state.progress) {
        this.$store.dispatch('setTitle', this.$store.state.config[this.$store.state.progress].title);
      } else {
        this.$store.dispatch('setTitle', this.$store.state.config.landing.title);
      }
      this.isModalVisible = false;
    },
    toggle() {
      this.$refs.fullscreen.toggle();
    },
    fullscreenChange(fullscreen) {
      this.fullscreen = fullscreen;
    },
  },
  mounted() {
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;

    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.windowWidth = window.innerWidth;
        this.windowHeight = window.innerHeight;
      });
    });

    const { dispatch } = this.$store;
    dispatch('AuthenticationStore/login');
  },
};
</script>

<style lang="scss">
  @import "./styles/scss/app.scss";
</style>
