/* eslint-disable */
export default {
    "core": {
        "type": "core",
        "logo": "/media/logo_white.svg",
        "logo_alt": "Logo BBird",
        "color": "#E94243",
        "title": "Mystère au bureau 403",
        "revelation_date": "09/30/2020 01:00",
        "clues": [
            "1_0_clue_1",
            "2_0_clue_1",
            "2_0_clue_2",
            "3_0_clue_1",
            "4_0_clue_1",
            "4_0_clue_2",
            "5_0_clue_1",
            "5_0_clue_2"
        ]
    },
    "not_available": {
        "type": "page",
        "title": "Mystère au bureau 403",
        "background": "/media/steps/splashscreen.jpg",
        "content": "Pour profiter de l’expérience, revenez ici via un ordinateur ou une tablette format paysage.",
    },
    "success": {
        "type": "page",
        "title": "Votre rapport a été envoyé",
        "content": {
            "title": "Votre rapport a été envoyé",
            "content": "<p>Merci pour votre implication. <br>Rendez-vous le 30 novembre pour comprendre et apprendre&nbsp;!</p>"
        },
    },
    "landing": {
        "type": "landing",
        "title": "Accueil",
        "background": "/media/steps/splashscreen.jpg",
        "content": {
            "title": "Mystère au bureau 403",
            "content": "<p style='width: 55%; margin: 0 auto;'>Visitez le bureau 403 et dévoilez le secret qui se cache en ces murs...</p>"
        },
        "steps": {
            "next": "landingBis",
            "previous": ""
        }
    },
    "landingBis": {
        "type": "landing",
        "title": "Introduction",
        "background": "/media/steps/splashscreen.jpg",
        "content": {
            "title": "Bureau 403 - 7h00",
            "title_discovered": "80% des handicaps sont invisibles.",
            "content": "<p>Vous venez d’arriver dans le bureau <span class='circler'>403. </span>Il est encore bien silencieux, la journée de travail n’a pas encore commencé.</p><p>La veille, plusieurs de vos collègues ont relevé des objets et des messages intrigants dans ce bureau. Vous êtes donc envoyé sur place pour découvrir ce qu’il s’y cache.</p><p>Parcourez les différents bureaux <span class='highlighter'>à la recherche des 8 indices</span> et répondez à la question ultime quand vous pensez avoir percé le secret du Mystère au Bureau 403.</p><p>Par<span class='eraser-bottom'>fois l’invisible</span> est bien visible mais très mystérieux...</p>",
            "content_discovered": "<p>Peut-être avez-vous remarqué que ces 8 objets mystérieux du Bureau 403 constituaient un aménagement de poste visant à faciliter le quotidien de personnes en situation de handicap ? En France, une personne sur deux sera concernée par une situation de handicap au cours de sa vie, ponctuelle ou durable. Et dans 80% des cas, ces situations sont invisibles !</p><p>Mais invisible ne signifie pas sans conséquences ! De l’aménagement d’horaires dans le cadre d’une maladie chronique, à l’utilisation d’une police de caractère spécifique pour la dyslexie, des aménagements existent pour adapter l’environnement de travail. \n</p><p>Découvrez vite les différentes situations de handicap illustrées et les <span class='highlighter'>aménagements</span> possibles dans votre carnet d’indices !</p>"
        },
        "steps": {
            "next": "tutorial"
        }
    },
    "tutorial": {
        "type": "tutorial",
        "title": "Vue d’ensemble - point de départ",
        "background": "/media/steps/start.jpg",
        "content": {},
        "clues": [],
        "steps": {
            "next": {
                "left": "1_0",
                "right": "5_0",
            },
            "previous": ""
        }
    },
    "1_0": {
        "type": "board",
        "title": "Bureau gauche 1",
        "background": "/media/steps/1_0.jpg",
        "content": {},
        "clues": [
            {
                "background": "/media/steps/1_0_clue_1.png",
                "point": "width: 13%;height: 7%;left: 31.5%;top: 53%;",
                "text": "Objet non identifié",
                "zoom": "1_0_clue_1"
            },
        ],
        "steps": {
            "next": {
                "left": "2_0",
                "right": "5_0",
                "left_text": "Aller à gauche",
                "right_text": "Aller à droite",
            },
            "previous": "tutorial"
        }
    },
    "2_0": {
        "type": "board",
        "title": "Bureau gauche 2",
        "background": "/media/steps/2_0.jpg",
        "content": {},
        "clues": [
            {
                "background": "/media/steps/2_0_clue_1.png",
                "point": "width: 17%;height: 25%;left: 15.5%;top: 57%;",
                "text": "Contact ICE",
                "zoom": "2_0_clue_1"
            },
            {
                "background": "/media/steps/2_0_clue_2.png",
                "point": "width: 12%;height: 12%;left: 67.5%;top: 68%;",
                "text": "Équipements ergonomiques",
                "zoom": "2_0_clue_2"
            },
        ],
        "steps": {
            "next": {
                "left": "3_0",
                "right": "1_0",
                "left_text": "Aller à gauche",
                "right_text": "Aller à droite",
            },
            "previous": "tutorial"
        }
    },
    "3_0": {
        "type": "board",
        "title": "Bureau droite 3",
        "background": "/media/steps/3_0.jpg",
        "content": {},
        "clues": [
            {
                "background": "/media/steps/3_0_clue_1.png",
                "point": "width: 4.1%;height: 5%;left: 51%;top: 50.5%;",
                "text": "Webcam",
                "zoom": "3_0_clue_1"
            },
        ],
        "steps": {
            "next": {
                "left": "4_0",
                "right": "2_0",
                "left_text": "Aller à gauche",
                "right_text": "Aller à droite",
            },
            "previous": "tutorial"
        }
    },
    "4_0": {
        "type": "board",
        "title": "Bureau droite 2",
        "background": "/media/steps/4_0.jpg",
        "content": {},
        "clues": [
            {
                "background": "/media/steps/4_0_clue_1.png",
                "point": "width: 18%;height: 13%;left: 41.5%;top: 62%;",
                "text": "Dossier confidentiel",
                "zoom": "4_0_clue_1"
            },
            {
                "background": "/media/steps/4_0_clue_2.png",
                "point": "width: 18%;height: 11%;left: 45%;top: 47%;",
                "text": "Carnet de notes",
                "zoom": "4_0_clue_2"
            },
        ],
        "steps": {
            "next": {
                "left": "5_0",
                "right": "3_0",
                "left_text": "Aller à gauche",
                "right_text": "Aller à droite",
            },
            "previous": "tutorial"
        }
    },
    "5_0": {
        "type": "board",
        "title": "Table",
        "background": "/media/steps/5_0.jpg",
        "content": {},
        "clues": [
            {
                "background": "/media/steps/5_0_clue_1.png",
                "point": "width: 7.8%;height: 13%;left: 37%;top: 55.5%;",
                "text": "Post-it",
                "zoom": "5_0_clue_1"
            },
            {
                "background": "/media/steps/5_0_clue_2.png",
                "point": "width: 23%;height: 34%;left: 49%;top: 36.3%;",
                "text": "Calendrier",
                "zoom": "5_0_clue_2"
            },
        ],
        "steps": {
            "next": {
                "left": "1_0",
                "right": "4_0",
                "left_text": "Aller à gauche",
                "right_text": "Aller à droite",
            },
            "previous": "tutorial"
        }
    },
    "1_0_clue_1": {
        "type": "zoom",
        "title": "Indice Stylo",
        "background": "/media/steps/1_0_clue_1_zoom.jpg",
        "content": {
            "title": "À quoi peut bien servir ce drôle de stylo ?",
            "discover-title": "Enregistreur et lecteur d’étiquettes",
            "discover-body": "<p>Les troubles de la vision sont une altération de l’acuité visuelle pouvant aller jusqu’à la cécité totale. <strong>Aujourd’hui, près de 2 millions de personnes sont atteintes d’un trouble de la vue.</strong> Des solutions simples peuvent permettre à l’employé de contourner les difficultés liées à son handicap. L’enregistreur/lecteur d’étiquettes en est un parmi tant d’autres ; il permet d’enregistrer des messages vocaux sur des étiquettes puis de les placer sur des objets choisis (dossiers, classeurs…). Il suffit ensuite de poser l’appareil sur l’étiquette pour en entendre le contenu. Des outils de synthèse vocale, des plages braille ou encore la mise en place de documents accessibles à tous les niveaux de l’entreprise permettent de faciliter le quotidien des personnes malvoyantes.</p>",
            "book-img": "/media/book/indice_08.png",
            "book-title": "Objet non identifié",
            "book-paraph": "À quoi peut bien servir ce drôle de stylo ?",
            "book-block-css": "top: 67%; left: 54%; max-width: 174px;",
        },
        "clues": [],
        "steps": {
            "next": {
                "left": "",
                "right": "",
            },
            "previous": "1_0"
        }
    },
    "2_0_clue_1": {
        "type": "zoom",
        "title": "Indice ICE",
        "background": "/media/steps/2_0_clue_1_zoom.jpg",
        "content": {
            "title": "ICE ? étrange ce pseudonyme...",
            "discover-title": "Numéro ICE",
            "discover-body": "<p><strong>Le handicap nécessite la mise en place de dispositifs et d’innovations qui permettent une prise en charge globale et personnalisée des personnes concernées.</strong> En cas d'urgence, chaque seconde est précieuse. Lorsqu’un numéro ICE (In Case of Emergency) est programmé, les secouristes savent immédiatement qui avertir. La procédure est simple : il suffit de créer un contact sous le nom « ICE » dans le répertoire de son téléphone et d'entrer le/les numéro(s) de(s) la/les personne(s) à contacter en cas d'urgence. Ce numéro permet aux professionnels de santé de connaître le profil médical de l’individu et d’agir le plus efficacement possible.</p>",
            "book-img": "/media/book/indice_07.png",
            "book-title": "Téléphone",
            "book-paraph": "«ICE» ? Étrange ce pseudonyme...",
            "book-block-css": "top: 46%; left: 68%; max-width: 150px;",
        },
        "clues": [],
        "steps": {
            "next": {
                "left": "",
                "right": "",
            },
            "previous": "2_0"
        }
    },
    "2_0_clue_2": {
        "type": "zoom",
        "title": "Indice Équipements ergonomiques",
        "background": "/media/steps/2_0_clue_2_zoom.jpg",
        "content": {
            "title": "Cette personne semble bien équipée...",
            "discover-title": "Équipements ergonomiques",
            "discover-body": "<p>Une personne atteinte de lombalgie chronique ou d’hernie discale peut souffrir d’un manque d’assiduité et d’absences répétées. <strong>En réalité, ces handicaps invisibles nécessitent souvent des aménagements ergonomiques de poste.</strong> Ainsi des équipements ergonomiques et du télétravail suffisent souvent à compenser ces pathologies pour laisser s’exprimer les compétences du salarié qui en est atteint. L’ergonomie peut être étudiée avec l’aide d’un ergonome qui viendra analyser le poste de travail et préconiser un matériel adapté à la situation de handicap : fauteuil, souris et tapis de souris, repose-pieds, éclairage adapté… Les équipements ergonomiques permettent également de prévenir l’arrivée de troubles musculo-squelettiques.</p>",
            "book-img": "/media/book/indice_04.png",
            "book-title": "Matériel",
            "book-paraph": "Cette personne semble bien équipée...",
            "book-block-css": "top: 72%;left: 19%;max-width: 140px;",
        },
        "clues": [],
        "steps": {
            "next": {
                "left": "",
                "right": "",
            },
            "previous": "2_0"
        }
    },
    "3_0_clue_1": {
        "type": "zoom",
        "title": "Indice Webcam",
        "background": "/media/steps/3_0_clue_1_zoom.jpg",
        "content": {
            "title": "Quelqu’un nous surveille ?",
            "discover-title": "Webcam allumée",
            "discover-body": "<p>Si un nombre considérable d’informations circulent par de simples échanges oraux au sein de l’entreprise, <strong>leur interprétation peut très vite devenir un calvaire pour une personne malentendante.</strong> Un collègue peut tout simplement retranscrire simultanément à l’écrit une conversation ou des propos tenus en réunion. Pour plus d’indépendance, il existe des applications retranscrivant instantanément la parole des interlocuteurs, ainsi que des claviers adaptés. La webcam permet d’échanger plus simplement avec des services de transcription instantanée par des interprètes en Langue des Signes.</p>",
            "book-img": "/media/book/indice_02.png",
            "book-title": "Webcam",
            "book-paraph": "Quelqu’un nous surveille ?",
            "book-block-css": "top: 37%;right: 55%;max-width: 185px;text-align: right;",
        },
        "clues": [],
        "steps": {
            "next": {
                "left": "",
                "right": "",
            },
            "previous": "3_0"
        }
    },
    "4_0_clue_1": {
        "type": "zoom",
        "title": "Indice Dossier confidentiel",
        "background": "/media/steps/4_0_clue_1_zoom.jpg",
        "content": {
            "title": "Drôlement lisible pour un projet secret.",
            "discover-title": "Typographie accessible",
            "discover-body": "<p><strong>La dyslexie touche l’apprentissage du langage écrit.</strong> Les personnes dyslexiques éprouvent des difficultés pour lire de manière fluide. Certaines polices de caractères facilitent donc grandement la lecture. Généralement, elles leur permettent de mieux faire la différence entre les lettres comme b, d, p, q et u/n qui sont les plus confondues. Utiliser une taille de police plus grosse, un interlignage plus important, préférer le gras, et alterner les couleurs d’une ligne à l’autre, sont des gestes simples qui permettent une meilleure lisibilité des documents partagés. Des logiciels dédiés permettent également d’installer des profils de lecture spécifique sur les navigateurs et ainsi rendre accessible les sites internet.</p>",
            "book-img": "/media/book/indice_05.png",
            "book-title": "Dossier confidentiel",
            "book-paraph": "Drôlement lisible pour un projet secret.",
            "book-block-css": "top: 30%;left: 53.5%;max-width: 144px;",
        },
        "clues": [],
        "steps": {
            "next": {
                "left": "",
                "right": "",
            },
            "previous": "4_0"
        }
    },
    "4_0_clue_2": {
        "type": "zoom",
        "title": "Indice Carnet de notes",
        "background": "/media/steps/4_0_clue_2_zoom.jpg",
        "content": {
            "title": "Difficile à relire...",
            "discover-title": "Carnet avec écritures difficilement déchiffrables",
            "discover-body": "<p>Les troubles Dys sont des troubles spécifiques qui affectent les fonctions cognitives du cerveau. <strong>Parmi eux, la dyspraxie est un trouble qui affecte la réalisation et l’automatisation des gestes.</strong> Concrètement, pour des gestes réalisés de façon automatique par les autres, le travailleur dyspraxique devra fournir des efforts bien plus importants. L’une des conséquences est notamment la dysgraphie, des difficultés à automatiser le dessin des lettres. La mise en place d’aides techniques est une bonne solution afin d’accompagner les personnes dont le handicap trouble la motricité (logiciels correcteurs, dictée vocale, écrans adaptés…).</p>",
            "book-img": "/media/book/indice_06.png",
            "book-title": "Notes",
            "book-paraph": "Difficile à relire...",
            "book-block-css": "top: 23%;left: 70%;max-width: 129px;",
        },
        "clues": [],
        "steps": {
            "next": {
                "left": "",
                "right": "",
            },
            "previous": "4_0"
        }
    },
    "5_0_clue_1": {
        "type": "zoom",
        "title": "Indice Post-it",
        "background": "/media/steps/5_0_clue_1_zoom.jpg",
        "content": {
            "title": "Que se passe-t-il le mercredi ?",
            "discover-title": "Post-it",
            "discover-body": "<p><strong>Le télétravail constitue un aménagement organisationnel important pour compenser des situations de handicap généralement invisibles.</strong> C’est notamment le cas pour des situations liées à une maladie chronique, à des troubles cognitifs… Plus largement, il permet aux travailleurs en situation de handicap de mieux gérer les contraintes liées à leur traitement et leur suivi médical, il évite d’accentuer la fatigue déjà provoquée par la maladie et permet une articulation plus flexible entre la vie quotidienne et l’univers professionnel. Au-delà du télétravail, l’organisation peut également être revu par un aménagement des horaires ou des pauses pour des métiers où le télétravail est impossible.</p>",
            "book-img": "/media/book/indice_03.png",
            "book-title": "Post-it",
            "book-paraph": "«Demain c’est mercredi, on fait comme d’habitude»",
            "book-block-css": "top: 56%;left: 31%;max-width: 185px;",
        },
        "clues": [],
        "steps": {
            "next": {
                "left": "",
                "right": "",
            },
            "previous": "5_0"
        }
    },
    "5_0_clue_2": {
        "type": "zoom",
        "title": "Indice Calendrier",
        "background": "/media/steps/5_0_clue_2_zoom.jpg",
        "content": {
            "title": "Pourquoi certains jours sont surlignés ?",
            "discover-title": "Calendrier Mural",
            "discover-body": "<p><strong>L'aménagement du temps de travail peut se traduire par la prescription d'un temps partiel ou d’un aménagement d’horaires par le médecin.</strong> Il peut s’agir d’un temps partiel thérapeutique ou d’un temps partiel avec modification du contrat de travail négocié avec l’employeur. Le médecin du travail est le premier conseiller en ce qui concerne l’adaptation des postes. C’est lui qui analysera la situation en amont de toute autre étude et fera les premières préconisations.</p>",
            "book-img": "/media/book/indice_01.png",
            "book-title": "Calendrier",
            "book-paraph": "Pourquoi certains jours sont surlignés ?",
            "book-block-css": "top: 20%; left: 32%; max-width: 160px;",
        },
        "clues": [],
        "steps": {
            "next": {
                "left": "",
                "right": "",
            },
            "previous": "5_0"
        }
    },
}
