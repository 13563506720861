<template>
  <button
      v-if="!$store.state.success"
      @mouseover="$store.dispatch('enableCursor')"
      @mouseout="$store.dispatch('disableCursor')"
      v-on:click="returnClick"
      aria-labelledby="return-btn-label"
      class="return-btn focus">
      <svg width="13px" height="13px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10"><defs/><path d="M5.88 5l3.94-3.93a.63.63 0 10-.89-.89L5 4.12 1.07.18a.63.63 0 10-.89.89L4.12 5 .18 8.93a.63.63 0 10.89.89L5 5.88l3.93 3.94a.62.62 0 10.89-.89L5.88 5z"/></svg>
      <span class="btn-text" id="return-btn-label" role="tooltip">Retour</span>
  </button>
</template>

<script>
export default {
  name: 'HubReturnBtn',
  props: {},
  methods: {
    returnClick() {
      this.$store.dispatch('disableCursor');
      this.$store.dispatch('setTitle', this.$store.state.config[this.$store.state.progress].title);

      if (this.$store.getters.isLogbook && !this.$store.getters.isQuit) {
        this.$store.dispatch('disableBook');
        // eslint-disable-next-line
        return;
      }

      if (this.$store.getters.isZoom && !this.$store.getters.isQuit && !this.$store.state.discover) {
        this.$store.dispatch('nextPage', this.$store.state.config[this.$store.state.progress].steps.previous);
        this.$store.dispatch('nextPageType', this.$store.state.config[this.$store.state.progress].type);
        // eslint-disable-next-line
        return;
      }

      if (this.$store.getters.isZoom && !this.$store.getters.isQuit && this.$store.state.discover) {
        this.$store.dispatch('nextPage', this.$store.state.config.landingBis.steps.next);
        this.$store.dispatch('nextPageType', this.$store.state.config.landingBis.steps.next);
        this.$store.dispatch('enableBook');
        this.$store.dispatch('setTitle', 'Carnet d\'indices');
        // eslint-disable-next-line
        return;
      }

      if (this.$store.getters.isQuit) {
        this.$store.dispatch('disableQuit');
        // eslint-disable-next-line
        return;
      }

      if (this.$store.getters.isTheory) {
        this.$store.dispatch('disableTheory');
        // eslint-disable-next-line
        return;
      }
    },
  },
};
</script>
