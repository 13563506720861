<template>
  <transition name="modal-fade">
    <div class="modal-backdrop" v-on:click="close">
      <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription" @click.stop @mouseover="$store.dispatch('invertCursor')" @mouseout="$store.dispatch('invertCursor')">
        <header class="modal-header" id="modalTitle" >
            <button type="button" class="btn-close"
                @mouseover="$store.dispatch('enableCursor')"
                @mouseout="$store.dispatch('disableCursor')"
                @click="close" aria-label="Close modal">
                <svg width="10px" height="10px" viewBox="0 0 10 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <path d="M5.88438848,4.99989577 L9.8167643,1.06751996 C10.0610786,0.823414146 10.0610786,0.427341519 9.8167643,0.183235705 C9.57245002,-0.0610785683 9.17700277,-0.0610785683 8.93248004,0.183235705 L5.00010423,4.11561152 L1.06772842,0.183235705 C0.823414146,-0.0610785683 0.427549978,-0.0610785683 0.183235705,0.183235705 C-0.0610785683,0.427341519 -0.0610785683,0.823414146 0.183235705,1.06751996 L4.11602843,4.99989577 L0.183235705,8.93227158 C-0.0610785683,9.17658585 -0.0610785683,9.57245002 0.183235705,9.81655584 C0.305392842,9.93850451 0.465489567,9.99958308 0.625377832,9.99958308 C0.785474558,9.99958308 0.945571283,9.93850451 1.06772842,9.81634738 L5.00010423,5.88397157 L8.93248004,9.81634738 C9.05463718,9.93850451 9.21494236,9.99958308 9.37462217,9.99958308 C9.53471889,9.99958308 9.69460716,9.93850451 9.8167643,9.81634738 C10.0610786,9.57224156 10.0610786,9.17616894 9.8167643,8.93206312 L5.88438848,4.99989577 Z" id="Fill-1"></path>
                </svg>
                <span class="btn-text" id="return-btn-label" role="tooltip">Fermer</span>&nbsp;
            </button>
        </header>
        <section class="modal-body" id="modalDescription">
          <slot name="title"></slot>
          <slot name="subtitle"></slot>
          <slot name="body"></slot>
        </section>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Modal',
  methods: {
    close() {
      this.$emit('close');
      this.$store.dispatch('disableCursor');
      if (this.$store.state.invertCursor) {
        this.$store.dispatch('invertCursor');
      }
    },
  },
};
</script>
