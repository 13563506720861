<template>
<div id='theory'>
    <div class="theory-block">
      <h1 tabindex="-1" v-focus>Vous pensez avoir percé le Mystère au bureau 403 ?</h1>
      <form action="" method="post" class="form" v-on:submit.prevent="submitTheory">
          <div class="form-row fieldset">
            <p>Alors à votre avis, que se passe-t-il ?</p>
            <p>
              <input type="radio" id="ans-a" name="radio-group" value="A" v-model="radio">
              <label for="ans-a"
                     @mouseover="$store.dispatch('enableCursor')"
                     @mouseout="$store.dispatch('disableCursor')">A. Des personnes cachent leur situation de handicap avec des outils spéciaux.</label>
            </p>
            <p>
              <input type="radio" id="ans-b" name="radio-group" value="B" v-model="radio">
              <label for="ans-b"
                     @mouseover="$store.dispatch('enableCursor')"
                     @mouseout="$store.dispatch('disableCursor')">B. Des personnes en situation de handicap travaillent dans ce bureau et ont des aménagements pour faciliter leur quotidien professionnel.</label>
            </p>
            <p>
              <input type="radio" id="ans-c" name="radio-group" value="C" v-model="radio">
              <label for="ans-c"
                     @mouseover="$store.dispatch('enableCursor')"
                     @mouseout="$store.dispatch('disableCursor')">C. Des collègues de travail se sont amusés à cacher des objets mystérieux pour attirer l’attention.</label>
            </p>
          </div>
          <p class="alert-radio" v-if="!this.radioSelected">Vous devez sélectionner une réponse</p>
          <button class="submit" type="submit"
            @mouseover="$store.dispatch('enableCursor')"
            @mouseout="$store.dispatch('disableCursor')">Valider ma réponse</button>
      </form>
    </div>
</div>
</template>

<script>
export default {
  name: 'Theory',
  props: {},
  data() {
    return {
      message: '',
      email: '',
      radio: '',
      radioSelected: true,
    };
  },
  methods: {
    reloadPage() {
      document.location.reload();
    },
    submitTheory() {
      if (!this.$store.state.submitted && this.radio !== '') {
        this.$store.dispatch('UserStore/postTheory', {
          answer: this.radio,
        });

        this.radio = '';
      } else {
        this.radioSelected = false;
      }
    },
  },
};
</script>
